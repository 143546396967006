import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { themeGet } from 'styled-system'
import Layout from '../layouts'
import Text from '../components/Text'
import styled from 'styled-components'
import {PostLink} from "../components/Link"
import { Post, PostHeader, PostDescription,  PostDate, Thumbnail } from "../components/Post"

const PageHeader = styled.h1`
  font-size: ${themeGet('headings.9')};
  margin-top: 0;

  @media (max-width: ${themeGet('breakpoints.4')}) {
    font-size: ${themeGet('headings.8')};
  }
  @media (max-width: ${themeGet('breakpoints.2')}) {
    font-size: ${themeGet('headings.6')};
  }
`

const BlogList = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, auto);
  grid-column-gap: 2em;
  grid-row-gap: 5.5em;
  margin: 0 auto;
  position: relative;
  @media (max-width: ${themeGet('breakpoints.2')}) { 
    grid-column-gap: 5em;
    grid-template-columns: repeat(1, 1fr);
  }
`

const FirstBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 1 / -1;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${themeGet('breakpoints.2')}) { 
    margin: auto;
    grid-column-gap: 5em;
  }
`
const SecondBlock = styled.div`
  align-items: flex-start;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2em;
  position: relative;
  @media (max-width: ${themeGet('breakpoints.2')}) { 
    grid-column-gap: 5em;
    grid-template-columns: repeat(1, 1fr);
  }
`

const ChangeView = styled.div `
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 2em 0;
`
const ListChange = styled.div`
  padding: 0.6em 0;
`

const ChangeButton = styled.span `
  padding-right: 2em;
  color: gray;
`

const ListLayout = styled.div`
  & h1 {
    margin: 1em 0 0;
  }

  & p {
    margin-top: 0;
  }
`

class TagRoute extends React.Component {
  constructor(props) {
    super(props)

      this.state = {
        view: true
      };

      this.changeView = this.changeView.bind(this);
  }
  changeView(flag){
      this.setState({
        view: flag
      });
  }

  renderList() {
    const list = this.props.data.allMdx.edges;
    if(this.state.view){
      return(
        <BlogList>
          <FirstBlock key="0">
            <Thumbnail  height="560px"  style={{ background: `url(${list[0].node.frontmatter.image}) center center`, backgroundSize: 'cover'}} alt="logo" />
            <Post>
              <PostLink to={list[0].node.fields.slug}>
                <PostDate>{list[0].node.frontmatter.date}</PostDate>
                <PostHeader>{list[0].node.frontmatter.title}</PostHeader>
                <PostDescription>{list[0].node.frontmatter.description}</PostDescription>
                <PostDate>Read more...</PostDate>
              </PostLink>
            </Post>
          </FirstBlock>
          <SecondBlock>
            { this.renderSecondBlock() }
          </SecondBlock>
          {this.renderThirdBlock()}
        </BlogList>
      )
    } else {
      return list.map((article, i) => {
        return (
          <ListLayout key={i}>
            <PostLink to={article.node.fields.slug}>
              <PostHeader fontSize={"3.2em"}>{article.node.frontmatter.title}</PostHeader>
              <PostDate>{article.node.frontmatter.date}</PostDate>
            </PostLink>
          </ListLayout>
        )
        })
    }
    
  }

  renderSecondBlock() {
    const list = this.props.data.allMdx.edges;
    
    return list.map((article, i) => {
      if(i===1 || i===2) {
        return (
          <div key={i}>
            <Thumbnail height="272px" style={{ background: `url(${article.node.frontmatter.image}) center center`, backgroundSize: 'cover'}} alt="logo" />
            <Post>
              <PostLink to={article.node.fields.slug}>
                <PostHeader>{article.node.frontmatter.title}</PostHeader>
                <PostDescription>{article.node.frontmatter.description}</PostDescription>
                <PostDate>{article.node.frontmatter.date}</PostDate>
              </PostLink>
            </Post>
          </div>
        )
      }
    })
    
  }

  renderThirdBlock() {
    const list = this.props.data.allMdx.edges;
    
    return list.map((article, i) => {
      if(i !== 0 && i!==1 && i!==2) {
        return (
          <div key={i}>
            <Thumbnail style={{ background: `url(${article.node.frontmatter.image}) center center`, backgroundSize: 'cover'}} alt="logo" />
            <Post>
              <PostLink to={article.node.fields.slug}>
                <PostHeader>{article.node.frontmatter.title}</PostHeader>
                <PostDescription>{article.node.frontmatter.description}</PostDescription>
                <PostDate>{article.node.frontmatter.date}</PostDate>
              </PostLink>
            </Post>
          </div>
        )
      }
      })
    
  }

  render() {
    const posts = this.props.data.allMdx.edges
    
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMdx.totalCount
    const tagHeader = `${tag}`

    return (
      <Layout>
          <Helmet title={`${tag} | ${title}`} />
          <Text fontSize={7, 5} marginTop="100px">Tagged In</Text>
          <PageHeader>{tagHeader}</PageHeader>
          <ChangeView>
            <ListChange>
              <ChangeButton onClick={() => this.changeView(false)}><img src={this.state.view ? "/img/list-icon.png" : "/img/list-icon-checked.png"}/></ChangeButton>
              <ChangeButton onClick={() => this.changeView(true)}><img src={this.state.view ? "/img/block-icon-checked.png" : "/img/block-icon.png"}/></ChangeButton>
            </ListChange>
          </ChangeView>
          {this.renderList()}
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD - YYYY")
            image
            tags
            categories 
          }
        }
      }
    }
  }
`
